import React from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";
import User from "./User";
import Calendar from "./Calendar";
import Role from "./Role";
import { Horse } from "./Horse";
import PrestationType from "./PrestationType";
import { Prestation } from "./Prestation";
import Login from "./Login";
import CoffeeTransactions from "./CoffeeTransactions";
import { isLoggedIn } from "../helpers/auth";
import { Topbar } from "./Topbar";
import Export from "./Export";
import { Scrollbar } from "../components/Scrollbar";

export const RoutedContent = ({ height }) => {
  var location = useLocation().pathname;
  if (!isLoggedIn() && location !== "/login") {
    return (
      <div className="vw-100 vh-100 d-flex">
        <div className="d-flex m-auto">
          <Switch>
            <Route path="/" component={Login} />
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Topbar />
        <Scrollbar className="app-main d-flex" style={{ height: height - 70 }}>
          <Switch>
            <Redirect from="/" to="/dashboard" exact />

            <Route path="/login" exact component={Login} />

            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/dashboard/projects" exact />
            <Route path="/calendar" exact component={Calendar} />
            <Route path="/export" exact component={Export} />

            <Route path="/users" exact component={User} />
            <Route path="/coffee" exact component={CoffeeTransactions} />
            <Route path="/horses" exact component={Horse} />
            <Route path="/prestations" exact component={Prestation} />
            <Route path="/prestationstypes" exact component={PrestationType} />
            <Route path="/roles" exact component={Role} />
          </Switch>
        </Scrollbar>
      </>
    );
  }
};
