import Axios from "axios";
import GlobalService from "./_GlobalService";
import UserService from "./UserService";
import { customFetch } from "../helpers/fetch";

class PrestationService extends GlobalService {
  constructor() {
    super("prestations");
  }

  getAllLastYear = () =>
    new Promise((resolve, reject) => {
      customFetch("POST", this.baseUrl + "/search", {
        period: "last_year",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => reject(e));
    });

  getAllForUser = (idUser) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/search`, { idUser: idUser.toString() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });

  getAllForCalendar = () =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/search`, { isForCalendar: true })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => reject(e));
    });
}

export default new PrestationService();
