import React from "react";
import Table from "../../components/Table";
import Panel from "../../components/Panel";
import { Col } from "reactstrap";
import { InputText, InputDate, InputSelect } from "../../components/Inputs";
import { PrestationService, PrestationUserService, PrestationTypeService } from "../../services/Services";
import Button from "../../components/Button";
import Notifier from "../../components/Notifier";
import moment from "moment";
import { Dialog, DialogTitle, DialogContent, TextField } from "@material-ui/core";
import { EditPrestationPanel } from "../Prestation";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createDate, jsDateToString, momentDateToString, stringDateToFormattedDate } from "../../helpers/date";
import EditUserPrestationDialog from "./EditUserPrestationDialog";
import CustomDialogTitle from "../../components/Dialog/dialog-title";

export default function UserPrestations(props) {
  const { user, prestations, handleUpdate } = props;

  const [presta, setPresta] = React.useState({
    name: null,
    type: null,
    price: null,
    start: momentDateToString(moment().startOf("month")),
    end: momentDateToString(moment().endOf("month")),
  });
  const [loading, setLoading] = React.useState(false);
  const [userPrestations, setUserPrestations] = React.useState([]);
  const [popUpPrestaOpen, setPopUpPrestaOpen] = React.useState(false);
  const [popUpPrestaUserOpen, setPopUpPrestaUserOpen] = React.useState(false);
  const [prestaUserEditing, setPrestaUserEditing] = React.useState();
  const [prestaLastYear, setPrestaLastYear] = React.useState();
  const [prestaChosen, setPrestaChosen] = React.useState();

  React.useEffect(() => {
    setPrestaLastYear(prestations);
  }, [prestations]);
  
  React.useEffect(() => {
    setUserPrestations(
      user.prestationUser
        .sort((a, b) => {
          var start1 = a.start;
          var start2 = b.start;
          if (moment(createDate(start1)).isBefore(createDate(start2))) return 1;
          if (moment(createDate(start1)).isAfter(createDate(start2))) return -1;
          return 0;
        })
        .map((pu) => ({
          ...pu,
          type: pu.prestation.type,
          start: pu.prestation.start,
          color: pu.prestation.color,
          end: pu.prestation.end,
          comment: pu.comment != null && pu.comment != "" ? pu.comment : pu.prestation.comment,
          price: pu.price != null && pu.price != "" ? pu.price : pu.prestation.price,
          idPresta: pu.prestation.id,
          idUser: user.id,
        }))
    );

    setLoading(false);
    setPrestaChosen(null);
  }, [user]);

  const onInputChange = (event, value) => {
    setPresta({ ...presta, [event.target.name]: value });
  };

  const onPrestationChosen = () => {
    if (prestaChosen && prestaChosen.id >= 0) {
      let request = {
        comment: presta.comment,
        price: presta.price,
        idPresta: prestaChosen.id,
        idUser: user.id,
      };
      PrestationUserService.addUser(request)
        .then(() => {
          Notifier.showNotification("success", `Prestation ajoutée avec succès`);
          handleUpdate();
          /* presta.idUser = user.id;
          presta.idPresta = presta.id;
          PrestationUserService.update(presta)
            .then(() => {
              setPrestaChosen({ id: -1 });
              handleUpdate();
              Notifier.showNotification("success", `Prestation ajoutée avec succès`);
            })
            .catch((e) => {
              setPrestaChosen({ id: -1 });
              handleUpdate();
              Notifier.showNotification("error", `Erreur lors de la sauvegarde`);
              console.error(e);
            });*/
        })
        .catch((e) => {
          console.error(e);
          Notifier.showNotification("error", `Erreur lors de la création ${e}`);
        });
    } else {
      Notifier.showNotification("error", `Il faut sélectionner une préstation dans la liste déroulante avant de l'ajouter`);
    }
  };

  const onPrestationColumnClicked = (row) => {
    PrestationUserService.setPayed(row)
      .then(() => {
        handleUpdate();
        Notifier.showNotification("success", `Prestation correctement payée`);
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de l'édition ${e}`);
      });
  };

  const onPrestationEditClicked = (row) => {
    console.log(row);
    setPrestaUserEditing(row);
    setPopUpPrestaUserOpen(true);
  };

  return (
    <div className="mt-2">
      {user && (
        <>
          <EditUserPrestationDialog
            data={prestaUserEditing}
            open={popUpPrestaUserOpen}
            handleUpdate={() => {
              setPopUpPrestaUserOpen(false);
              handleUpdate();
            }}
            handleOpenPrestation={() => {
              setPopUpPrestaOpen(true);
              setPopUpPrestaUserOpen(false);
            }}
            handleClose={() => setPopUpPrestaUserOpen(false)}
          />

          {prestaUserEditing && (
            <Dialog maxWidth={false} onClose={() => setPopUpPrestaOpen(false)} aria-labelledby="customized-dialog-title" open={popUpPrestaOpen}>
              <CustomDialogTitle onClose={() => setPopUpPrestaOpen(false)}>Édition préstation</CustomDialogTitle>
              <DialogContent dividers className="d-flex flex-column p-3">
                <EditPrestationPanel
                  data={prestaUserEditing.prestation}
                  handleChange={() => {
                    handleUpdate();
                    setPopUpPrestaOpen(false);
                  }}
                />
              </DialogContent>
            </Dialog>
          )}

          <Panel label="Ajout d'une prestation" className="mb-3">
            <div className="clearfix mt-3">
              <Autocomplete
                id="app-select-presta-user"
                style={{ width: "100%" }}
                options={prestaLastYear ? prestaLastYear : []}
                getOptionLabel={(presta) => presta.fullname}
                getOptionSelected={(option, value) => (value && value.id ? option.id === value.id : false)}
                value={prestaChosen}
                onChange={(event, newValue) => {
                  setPrestaChosen(newValue);
                  setPresta({ ...newValue, comment: "" });
                }}
                renderInput={(params) => <TextField {...params} label="Choisir une préstation existante" variant="outlined" />}
              />
            </div>
            {prestaChosen && (
              <div className="clearfix">
                <div className="clearfix">
                  <Col xs="auto" className="p-0 mt-3 mr-2">
                    <InputText name="price" title="Prix" type="price" value={presta.price} style={{ width: "85px" }} handleChange={onInputChange} />
                  </Col>
                </div>
                <Col xs="12" className="p-0 mt-3">
                  <InputText
                    name="comment"
                    title="Commentaire"
                    multiline
                    rows="2"
                    style={{ width: "100%" }}
                    handleChange={onInputChange}
                    value={presta.comment}
                  />
                </Col>
                <Col xs="12" className="p-0 mt-3">
                  <Button onClick={onPrestationChosen} color="primary">
                    AJOUTER
                  </Button>
                </Col>
              </div>
            )}
          </Panel>
          <Panel label="Liste des prestations pour cet utilisateur" fullHeight className="col-md-12 mt-3 flex-shrink-1 flex-grow-1">
            <Table
              columns={[
                { title: "Payée", field: "payed", boolean: true, editable: true },
                { title: "Modèle", field: "template" },
                { title: "Commentaire", field: "comment" },
                { title: "Prix", field: "price" },
                { title: "Début", field: "start", dateFormat: true },
                { title: "Fin", field: "end", dateFormat: true },
              ]}
              data={userPrestations}
              className="small"
              handleColumnEdit={onPrestationColumnClicked}
              handleEdit={onPrestationEditClicked}
              options={{
                search: true,
                noPadding: true,
                noSelection: true,
                selectedRowColor: "var(--gray)",
                headerStyle: {
                  backgroundColor: "var(--gray)",
                },
                affectRowColorBasedOnColumn: "color",
              }}
            >
              <colgroup>
                <col span="1" style={{ width: "60px" }} />
                <col span="1" />
                <col span="1" />
                <col span="1" />
                <col span="1" />
                <col span="1" />
                <col span="1" />
                <col span="1" style={{ width: "30px" }} />
              </colgroup>
            </Table>
          </Panel>
        </>
      )}
    </div>
  );
}
