import React, { Component } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "./icons";
import PropTypes from "prop-types";
import { ConditionalScrollBar, Scrollbar } from "../../components/Scrollbar";
import "./style.css";
import { IconButton, Checkbox } from "@material-ui/core";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { momentDateToString, stringDateToFormattedDate } from "../../helpers/date";
import { containsInvariant } from "../../helpers/helpers";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: false,
      sortField: "",
      search: "",
      sortOrder: "DESC",
      data: this.props.data,
      filteredData: this.props.data,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      this.setState({ data: this.state.data, filteredData: this.state.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    } else return null;
  }

  onRowClick = (selectedRow) => {
    this.setState({ selectedRow });
    if (this.props.handleSelection) this.props.handleSelection(selectedRow);
  };

  onHeaderClick = (field) => {
    let sortOrder;
    if (this.state.sortField != field) {
      sortOrder = "DESC";
    } else {
      sortOrder = this.state.sortOrder === "ASC" ? "DESC" : "ASC";
    }
    const data = this.state.filteredData;
    data.sort((a, b) => {
      if (isNaN(a[field]) || isNaN(b[field])) return ("" + a[field]).localeCompare("" + b[field]);
      else {
        return parseFloat(a[field]) > parseFloat(b[field]) ? 1 : -1;
      }
    });

    if (sortOrder === "DESC") data.reverse();

    this.setState({
      filteredData: data,
      sortField: field,
      sortOrder: sortOrder,
    });
  };

  onSearchValueChanged = (e) => {
    let search = e.target.value;
    if (search != undefined) {
      search = search.toLowerCase();
      const data = this.state.data;
      const filteredData = data.filter((item) => {
        var contains = false;
        this.props.columns.forEach((column) => {
          const field = column.field;
          if (containsInvariant(item[field], search)) {
            contains = true;
            return;
          }
        });
        if (contains) return item;
      });

      this.setState({ filteredData: filteredData, search: search });
    } else {
      this.setState({ search: search });
    }
  };

  onEditableColumnValueChanged = (event, itemModified) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newItem = {
      ...itemModified,
      [name]: value,
    };
    if (this.props.handleColumnEdit) {
      this.props.handleColumnEdit(newItem);
    }
  };

  onDelete = () => {};

  render() {
    const {
      props: {
        title,
        columns,
        options = {},
        sort,
        data,
        handleAdd,
        handleEdit,
        handleDelete,
        handleSelection,
        handleColumnEdit,
        className = "",
        children,
        ...other
      },
      state: { filteredData, selectedRow },
    } = this;

    const hasHeader = options.search || title || handleAdd;

    return (
      <div className={`app-table-wrapper d-flex flex-column ${className} ${options.noPadding ? "nopadding" : "p-2"}`} {...other}>
        {hasHeader ? (
          <div className="app-table-header" style={{ ...options.headerStyle }}>
            {options.search ? (
              <div className="app-table-search">
                <input value={this.state.search} onChange={this.onSearchValueChanged} type="search" placeholder="Rechercher" />
                <SearchIcon className="m-auto" />
              </div>
            ) : null}
            {title ? (
              <div className="app-table-title">
                <h3>{title}</h3>
              </div>
            ) : null}
            {handleAdd ? (
              <div className="app-table-add">
                <button className="app-table-button-icon" onClick={handleAdd} size="small">
                  <AddIcon />
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        <ConditionalScrollBar show={options?.fixedHeight}>
          <table className="app-table">
            {children}
            <thead className="app-table-head">
              <tr className="app-table-row">
                {columns.length > 0 &&
                  columns.map((column) => {
                    return (
                      <td key={column.field} onClick={() => this.onHeaderClick(column.field)}>
                        {column.title}

                        <IconButton size="small">
                          {this.state.sortField === column.field ? (
                            this.state.sortOrder === "ASC" ? (
                              <ArrowDropDownOutlinedIcon />
                            ) : (
                              <ArrowDropUpOutlinedIcon />
                            )
                          ) : (
                            <ArrowDropDownOutlinedIcon />
                          )}
                        </IconButton>
                      </td>
                    );
                  })}
                {handleDelete ? <td></td> : null}
              </tr>
            </thead>
            <tbody className="app-table-body">
              {filteredData &&
                filteredData.length > 0 &&
                filteredData.map((item) => {
                  let rowStyle = {};
                  if (options?.affectRowColorBasedOnColumn) {
                    rowStyle = { backgroundColor: `${item[options.affectRowColorBasedOnColumn]}` };
                  } else if (options?.affectRowColor?.length > 0) {
                    options.affectRowColor.forEach((condition) => {
                      if ((!condition.inversed && item[condition.column]) || (condition.inversed && !item[condition.column])) {
                        rowStyle = condition.style;
                      }
                    });
                  } else if (options?.selectedRowColor && item.id === selectedRow.id) {
                    rowStyle = { backgroundColor: options.selectedRowColor };
                  }

                  return (
                    <tr
                      key={item.id}
                      className={`app-table-row ${item.id === selectedRow.id && (!options || !options?.noSelection) ? "active" : ""}`}
                      onClick={() => this.onRowClick(item)}
                      style={rowStyle}
                    >
                      {columns.map((column) => {
                        var properties = Array.isArray(column.field) ? column.field : column.field.split(".");
                        var value = properties.reduce((prev, curr) => prev && prev[curr], item);

                        let onChangeEvent = null;
                        if (column.editable) {
                          onChangeEvent = (event) => this.onEditableColumnValueChanged(event, item);
                        }
                        if (column.boolean) {
                          return (
                            <td key={column.field} style={{ width: "1%", whiteSpace: "nowrap" }}>
                              <Checkbox color="primary" name={column.field} checked={value ? true : false} onChange={onChangeEvent} value={value} />
                            </td>
                          );
                        } else {
                          return (
                            <td key={column.field}>
                              {column.editable ? (
                                <input
                                  name={column.field}
                                  type="text"
                                  onChange={onChangeEvent}
                                  value={column.dateFormat ? stringDateToFormattedDate(value) : value}
                                />
                              ) : (
                                <span>{column.dateFormat ? stringDateToFormattedDate(value) : value}</span>
                              )}
                            </td>
                          );
                        }
                      })}
                      {handleDelete || handleEdit ? (
                        <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                          <div className="app-table-actions">
                            {handleEdit ? (
                              <button className="app-table-button-icon" onClick={() => handleEdit(item)} size="small">
                                <SearchIcon />
                              </button>
                            ) : null}
                            {handleDelete ? (
                              <button className="app-table-button-icon" color="danger" onClick={() => handleDelete(item)} size="small">
                                <DeleteIcon />
                              </button>
                            ) : null}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </ConditionalScrollBar>
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  options: PropTypes.object.isRequired,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleRowSelected: PropTypes.func,
  handleColumnEdit: PropTypes.func,
};

export default Table;
