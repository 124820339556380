import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect, useLocation } from "react-router-dom";
import { RoutedContent } from "./routes/index";
import { NotificationContainer } from "react-notifications";
import { isLoggedIn } from "./helpers/auth";

const basePath = process.env.BASE_PATH || "/";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <BrowserRouter basename={basePath}>
        <NotificationContainer />
        <RoutedContent height={this.state.height} />
      </BrowserRouter>
    );
  }
}

export default App;
