import React, { Component } from "react";
import ReactLoading from "react-loading";
import Table from "../../components/Table";
import { Scrollbar } from "../../components/Scrollbar";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { PrestationService, UserService } from "../../services/Services";
import EditPrestationPanel from "./EditPrestationPanel";
import CreatePrestation from "./CreatePrestation";
import moment from "moment";
import Notifier from "../../components/Notifier";
import { CONST_WIDTH_MOBILE } from "../../helpers/const";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Close } from "@material-ui/icons";
import CustomDialogTitle from "../../components/Dialog/dialog-title";

class Prestation extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      itemEditing: {},
      popUpEditOpen: false,
      loading: false,
    };
    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;

    this.refreshPrestations = this.refreshPrestations.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshPrestations();
  }

  refreshPrestations = () => {
    this.setState({ loading: true });
    PrestationService.getAll()
      .then((items) => {
        if (this._isMounted) this.setState({ items: items, loading: false, popUpEditOpen: false });
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la récupération des données`);
        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onItemEdit = (item) => {
    this.setState({ itemEditing: item, popUpEditOpen: true });
  };

  onItemCreate = () => {
    this.setState({ itemEditing: { id: -1 }, popUpEditOpen: true });
  };

  onItemDeleted = (item) => {
    PrestationService.delete(item.id)
      .then(() => {
        this.refreshPrestations();
        Notifier.showNotification("success", `La préstation à bien été supprimée`);
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la suppression`);
      });
  };

  handleClose = () => {
    this.setState({ popUpEditOpen: false });
  };

  render() {
    const {
      state: { items, itemEditing, popUpEditOpen, loading },
    } = this;

    return (
      <div className="d-flex flex-column flex-grow-1 p-2">
        {loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}

        <Dialog
          maxWidth={false}
          onClose={() => this.setState({ popUpEditOpen: false })}
          aria-labelledby="customized-dialog-title"
          open={popUpEditOpen}
        >
          <CustomDialogTitle id="customized-dialog-title" onClose={() => this.setState({ popUpEditOpen: false })}>
            Édition préstation
          </CustomDialogTitle>
          <DialogContent dividers className="d-flex flex-column p-3">
            <EditPrestationPanel data={itemEditing} handleChange={() => this.refreshPrestations()} />
          </DialogContent>
        </Dialog>
        <CreatePrestation handleCreate={() => this.refreshPrestations()} />
        <div className="shadowed w-100 h-100 p-6" style={{ backgroundColor: "#fff" }}>
          <Table
            columns={[
              { title: "Type", field: "template" },
              { title: "Commentaire", field: "comment" },
              { title: "Prix", field: "price" },
              { title: "Début", field: "dateStart", dateFormat: true },
              { title: "Fin", field: "dateEnd", dateFormat: true },
              { title: "Clients max", field: "max" },
            ]}
            data={items.sort((a, b) => {
              if (moment(a.dateStart).isBefore(b.dateStart)) return 1;
              if (moment(a.dateStart).isAfter(b.dateStart)) return -1;
              return 0;
            })}
            handleEdit={this.onItemEdit}
            handleDelete={(item) => this.onItemDeleted(item)}
            options={{
              search: true,
              noPadding: false,
            }}
            style={{ backgroundColor: "#fff", marginBottom: "80px" }}
          >
            <colgroup>
              <col span="1" style={{ width: "26%" }} />
              <col span="1" style={{ width: "26%" }} />
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "14%" }} />
              <col span="1" style={{ width: "14%" }} />
              <col span="1" style={{ width: "5%" }} />
              <col span="1" style={{ width: "4%" }} />
              <col span="1" style={{ width: "4%" }} />
              <col span="1" style={{ width: "4%" }} />
              <col span="1" style={{ width: "60px" }} />
            </colgroup>
          </Table>
        </div>
      </div>
    );
  }
}

export { Prestation, EditPrestationPanel, CreatePrestation };
